/* MOBILE FIRST DEVELOPMENT */

.container {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    @apply bg-primary-50  w-full border-b-[1px]  border-bg_e8e8e8 flex flex-col justify-between   ;
}

.buttonsContainer {
    @apply flex w-full justify-between  mt-3  px-6 pb-6;
}

@screen lg {
    .container {
        @apply h-40 rounded-lg  border-[1px] flex-row mb-7;
    }
    .buttonsContainer {
        @apply items-center mt-3 3xl:mt-4 w-[30%] pb-0 px-0 ltr:pr-6 rtl:pl-6;
    }
}

@screen 3xl {
    .buttonsContainer {
        @apply  mt-4 ;
    }
}