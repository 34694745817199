/* MOBILE FIRST DEVELOPMENT */

.main {
    @apply bg-bg_f7f7f7 py-6 mt-[108px] md:mt-[60px] xl:mt-[84px];
}

.container {
    @apply  py-10 px-10 flex flex-col items-center;
}

@screen md {
    .main {
        @apply py-10 px-9;
    }
    .container {
        @apply bg-bg_white px-10;
    }
}

@screen lg {
    .main {
        @apply py-10 px-9;
    }
    .container {
        @apply px-20;
    }
}

@screen xl {
    .main {
        @apply py-16 px-12;
    }
    .container {
        @apply px-20;
    }
}

@screen 2xl {
    .main {
        @apply py-20 px-24;
    }
}

@screen 3xl {
    .main {
        @apply py-24;
    }
}