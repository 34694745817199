/* MOBILE FIRST DEVELOPMENT */

.container {
    @apply flex items-center;
}

.icon {
    @apply w-3 h-3 cursor-pointer;
}

@screen 2xl {
    .icon {
        @apply w-4 h-4;
    }
}