/* MOBILE DEVELOPMENT FIRST */

.contaner {
  @apply bg-bg_white px-6 py-1;
}

.header {
  @apply flex items-center pb-2;
}

.headerIcon {
  @apply w-[25px] h-[17px] mr-3 rtl:ml-3;
}

.locationIcon {
  @apply h-[18px];
}

@screen lg {
  .contaner {
    @apply px-6  py-[30px];
  }
}
