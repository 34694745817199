/** MOBILE FIRST DEVELOPMENT **/

.form {
  @apply w-full h-full;
}

.formcontainer {
  @apply h-full flex flex-col items-center justify-evenly text-center;
}

.formHeader {
  @apply flex flex-col items-center;
}

.formInputsContainer {
  direction: initial !important;
  @apply w-full flex justify-center !important;
}

.formInputsContainer div {
  @apply flex justify-evenly w-4/5 !important;
}

.formInputsContainer input {
  @apply font-Raleway text-text_white text-5xl text-center font-bold overflow-hidden bg-transparent border-x-0 border-t-0 border-b-2 border-text_white rounded-none w-10 md:w-20 lg:w-24 h-16 !important;
}

@screen lg {
  .formInputsContainer div {
    @apply w-11/12 !important;
  }
}
