/* MOBILE FIRST DEVELOPMENT */

.newsLetterForm {
    @apply flex flex-col items-center justify-between mt-5;
}


@screen md {
    .newsLetterForm {
        @apply flex-row;
    }
}

@screen xl {
    .newsLetterHeading {
        @apply w-5/12 mx-auto;
    }
    .newsLetterForm {
        @apply w-[70%] mx-auto my-10 mt-10;
    }
}

@screen 2xl {
    .newsLetterForm {
        @apply w-[65%];
    }
}

@screen 3xl {
    .newsLetterForm {
        @apply w-1/2;
    }
}