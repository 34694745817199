/* MOBILE FISRT DEVELOPMENT */

.footer {
  @apply bg-bg_f7f7f7 mt-8;
}

.footerMainContainer {
  @apply bg-text_white py-6 px-6;
}


.newsLetterHeading {
  @apply flex flex-col items-center text-center;
}

.logo {
  @apply w-12 h-12 mb-4 cursor-pointer;
}

.footerNavlinksContiner {
  @apply flex flex-col justify-between items-center my-8;
}

.footerNavlinksContiner a {
  @apply mb-6;
}

.footerNavlinksContiner a:last-child {
  @apply mb-0;
}

.socialMediaLinksContainer {
  @apply flex w-1/2 justify-between items-end mx-auto;
}

.socialMedialogo {
  @apply h-7;
}

.socialMedialogo:nth-child(2),
.socialMedialogo:nth-child(4) {
  @apply h-6;
}

.copyRights {
  @apply w-full flex justify-center py-4 items-center;
}

@screen md {
  .footerMainContainer {
    @apply py-5 px-12;
  }
  .newsLetterHeading {
    @apply w-1/2 mx-auto;
  }
  .footerNavlinksContiner {
    @apply flex-row;
  }
  .newsLetterForm {
    @apply flex-row;
  }
}

@screen lg {
  .footer {
    @apply mt-16;
  }

  .footerMainContainer {
    @apply py-14 px-20;
  }

  .logo {
    @apply w-16 h-16;
  }

  .footerNavlinksContiner {
    @apply w-[70%] mx-auto my-10;
  }

  .newsLetterHeading {
    @apply w-5/12 mx-auto;
  }

  .footerNavlinksContiner a {
    @apply mb-0;
  }

  .socialMediaLinksContainer {
    @apply w-1/6;
  }

  .copyRights {
    @apply py-8;
  }
}

@screen 2xl {
  .newsLetterHeading {
    @apply w-1/4;
  }

  .footerNavlinksContiner {
    @apply w-[65%];
  }
}


@screen 3xl {
  .footerNavlinksContiner {
    @apply w-1/2;
  }
}
