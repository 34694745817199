/* MOBILE FIRST DEVELOPMENT */

.main {
  @apply bg-bg_f7f7f7 py-6 mt-[108px] md:mt-[60px] xl:mt-[84px];
}

.container {
  @apply px-6;
}

.sortFiltersContainer {
  @apply hidden;
}

@screen lg {
  .main {
    @apply py-10;
  }
  .container {
    @apply px-9;
  }
  .sortFiltersContainer {
    @apply flex py-4 border-b-[1px] border-t-[1px] border-text_bfbfbf;
  }
  .sortFilters {
    @apply mr-11 rtl:mr-0 rtl:ml-11;
  }
}

@screen xl {
  .container {
    @apply px-12;
  }
}

@screen 2xl {
  .main {
    @apply py-16;
  }
  .container {
    @apply px-24;
  }
  .sortFiltersContainer {
    @apply py-6;
  }
}

@screen 3xl {
  .main {
    @apply py-20;
  }
  .sortFiltersContainer {
    @apply py-10;
  }
  .sortFilters {
    @apply mr-14 rtl:mr-0 rtl:ml-14;
  }
}
