@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel.carousel-slider {
  padding:45px
}
html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
  font-weight: 600;
}

button:focus {
  outline: 0;
  /*  outline: 1px auto #fcfcfc; */
}

input[type="checkbox"],
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  @apply hidden;
}

.ellipseText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  white-space: nowrap;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  white-space: wrap;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: wrap;
}

.carousel .carousel-status {
  @apply hidden !important;
}

.carousel .slider-wrapper {
  @apply mb-8 !important;
}

.carousel-root {
  width: 100%;
  direction: initial;
}

.scrollHidden::-webkit-scrollbar {
  display: none;
}

/* Works on Firefox */
.scroll {
  scrollbar-width: thin;
  scrollbar-color: #bf0022 transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

/* Works on Chrome, Edge, and Safari */

.scroll::-webkit-scrollbar {
  width: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #bf0022;
  border-radius: 4px;
}

/* white scroll bar */
.whiteScroll {
  scrollbar-width: 16px;
  scrollbar-color: #fff transparent;
}

.whiteScroll::-webkit-scrollbar {
  width: 16px;
}

.whiteScroll::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.greyScroll {
  scrollbar-width: 10px;
  scrollbar-color: #d8d8d8 transparent;
}

.greyScroll::-webkit-scrollbar {
  width: 10px;
}

.greyScroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

/* Switch input theme */
.input-switch.style-switch-one input[type="checkbox"]:checked + label:before,
.input-switch.style-switch-one input[type="checkbox"]:checked + label {
  background-color: #bf0022 !important;
}

.input-switch.style-switch-one input[type="checkbox"] + label:before,
.input-switch.style-switch-one input[type="checkbox"] + label {
  background-color: #bf00233b !important;
}

.star {
  @apply !stroke-[#e5a128] rtl:scale-x-[-1] rtl:origin-center;
}

.widget-svg {
  @apply !w-[13px] sm:!w-[20px] !h-fit;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.rnc__notification-item--default {
  @apply bg-primary-800 border-l-[8px] border-l-[#bf0022] -mt-5;
}
