/* * MOBILE FIRST DEVELOPMENT * */

.RMenuCard {
    @apply w-full max-w-[500px] text-left rtl:text-right flex flex-col items-start md:m-0;
}

.RMenuCardMobile {
    margin-inline-start: 16px;
}

.RMenuImage {
    @apply relative w-full;
}

.overlay {
    @apply absolute top-5 left-0 right-0 h-[16px] md:h-[30px] w-[50%] overflow-hidden;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.712);
}

.OverlayText {
    @apply text-white text-sm md:text-lg absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] whitespace-nowrap;
}