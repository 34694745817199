/* MOBILE DEVELOPMENT FIRST */

.contaner {
  @apply bg-bg_white  px-6 py-1 flex-wrap;
}

.header {
  @apply flex items-center pb-2;
}

.headerIcon {
  @apply w-[20px] h-[17px] mr-3 rtl:ml-3;
}

.locationIcon {
  @apply h-[40px] sm:h-[20px] mx-1 mt-2;
}

@screen lg {
  .contaner {
    @apply px-6  py-[30px];
  }
}
