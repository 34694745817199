/* MOBILE FIRST DEVELOPMENT */

.offerContainer {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    @apply flex cursor-pointer rounded-lg h-[108px] mb-7 bg-primary-50;
}

.restaurantLogo {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    @apply bg-text_white rounded-lg;
}

.orderInfo {
    @apply px-4 flex flex-col justify-center;
}

@screen lg {
    .orderInfo {
        @apply px-8;
    }
}