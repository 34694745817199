/* MOBILE FIRST DEVELOPMENT */

.orderInfoContainer {
    @apply flex py-8 px-6;
}

.restaurantLogo {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    @apply bg-text_white rounded-[8px];
}

.orderInfo {
    @apply ltr:ml-4 rtl:mr-4 flex flex-col justify-between;
}

@screen lg {
    .orderInfoContainer {
        @apply py-0 px-0 h-full;
    }
    .orderInfo {
        @apply py-8;
    }
}