/* MOBILE FIRST DEVELOPMENT */

.main {
    @apply bg-bg_white py-6 mt-[108px] md:mt-[60px] xl:mt-[84px] px-10;
}

.container {
    @apply  py-6 flex flex-col items-center px-10;
}

@screen md {
    .main {
        @apply py-10 px-9;
    }
    .container {
        @apply bg-bg_f7f7f7 px-10 py-16;
    }
}

@screen lg {
    .main {
        @apply py-10 px-9;
    }
    .container {
        @apply px-20 py-16;
    }
}

@screen xl {
    .main {
        @apply py-16 px-12;
    }
    .container {
        @apply px-20;
    }
}

@screen 2xl {
    .main {
        @apply py-20 px-24;
    }
}

@screen 3xl {
    .main {
        @apply py-24;
    }
}