/** MOBILE FIRST DEVELOPMENT **/
.location {
  @apply text-[16px] lg:text-[20px] lg:h-[70px] h-[fit-content] py-2 mt-10 w-full z-20 bg-primary-50 shadow-[0_3px_6px_0_rgb(0,0,0,0.16)] text-center;
}
.main {
  @apply bg-bg_f7f7f7 mt-[108px];
}

.services {
  @apply pt-10 py-16 text-center;
}

.servicesContainer {
  @apply bg-primary-50 flex flex-col flex-wrap items-center;
}

.serviceContainer {
  @apply mb-16;
}

.serviceContainer:first-child {
  @apply mt-16;
}

.serviceContainer,
.serviceHeader {
  @apply flex flex-col items-center w-[215px];
}

.Offers,
.Categories,
.popular,
.orderAgain {
  @apply flex flex-col h-full flex-wrap items-center;
}

.Categories,
.popular {
  @apply my-14;
}

.Popular_res {
  @apply px-[80px] my-2;
}
.categoryCard {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  @apply cursor-pointer mx-2 bg-primary-900 w-60 h-16 rounded-[10px] flex items-center justify-center;
}

.icon {
  @apply mb-6;
}

.orderAgainHeader {
  @apply px-6 flex w-full justify-between;
}

.downloadApps {
  @apply text-center bg-primary-50 px-8 py-8;
}

.downloadAppsTextContainer {
  @apply my-auto;
}

.DownloadButtons {
  @apply w-full flex justify-between my-4;
}

@screen md {
  .main {
    @apply mt-[60px];
  }
  .servicesContainer {
    @apply grid grid-cols-2 justify-items-center;
  }

  .serviceContainer:nth-child(3),
  .serviceContainer:nth-child(4) {
    @apply mb-0;
  }

  .serviceContainer {
    @apply justify-center;
  }

  .serviceContainer:first-child {
    @apply mt-0;
  }

  .servicesContainer {
    @apply flex-row flex-wrap items-center justify-between px-11 py-7;
  }

  .downloadApps {
    @apply text-left rtl:text-right;
  }

  .DownloadButtons {
    @apply w-1/2;
  }
}

@screen lg {
  .main {
    @apply mt-[68px];
  }
  .servicesContainer {
    @apply flex flex-wrap items-center my-4;
  }

  .serviceContainer {
    @apply mb-1;
  }

  .downloadApps {
    @apply flex;
  }

  .DownloadButtons {
    @apply w-[60%];
  }

  .Categories,
  .popular,
  .servicesContainer,
  .Offers,
  .orderAgain {
    @apply px-6;
  }

  .categoryCard {
    @apply w-[200px];
  }

  .orderAgainHeader {
    @apply px-3;
  }

  .Offers {
    @apply py-11;
  }
}

@screen xl {
  .DownloadButtons {
    @apply my-8;
  }

  .servicesContainer {
    @apply mb-0 px-12 py-12;
  }

  .downloadApps {
    @apply pl-52 rtl:pr-52 pr-36 rtl:pl-36 py-12;
  }

  .Categories,
  .popular,
  .servicesContainer,
  .orderAgain,
  .Offers {
    @apply px-12;
  }
}

@screen 2xl {
  .serviceContainer {
    @apply mb-0;
  }

  .services {
    @apply pb-28;
  }

  /* .orderAgainHeader {
    @apply ltr:pr-3 ltr:pl-12 rtl:pr-12 rtl:pl-12;
  } */

  .Categories,
  .popular,
  .orderAgain {
    @apply px-[40px];
  }

  .servicesContainer,
  .Offers {
    @apply px-24;
  }

  .categoryCard {
    @apply w-60;
  }

  .downloadApps {
    @apply px-60;
  }

  .DownloadButtons {
    @apply w-[55%];
  }

  .downloadApps {
    @apply pl-[14rem] rtl:pr-[14rem] pr-52 rtl:pl-52 py-[3.5rem];
  }
}

@screen 3xl {
  .downloadApps {
    @apply pl-[17.5rem] rtl:pr-[17.5rem] pr-64 rtl:pl-64 py-[4.375rem];
  }
}
