/** MOBILE FIRST DEVELOPMENT **/

.main {
  @apply bg-bg_f7f7f7;
}

.OffersSection {
  @apply pb-3;
}

.RestaurantIntro {
  @apply bg-bg_white flex flex-col flex-wrap items-center w-full md:pt-16 pt-8 pb-4 md:pb-20 px-5;
}

.DeliverData {
  @apply text-[14px] md:py-3 sm:text-center;
}

.Discounts {
  @apply w-fit md:w-full flex flex-col items-center justify-center bg-bg_white md:bg-bg_f7f7f7 text-center min-h-[60px] md:min-h-[162px] max-w-[566px] rounded-lg py-2 px-4 mt-2;
}

.RestaurantData {
  @apply flex flex-col items-start gap-1;
}

.DeliverDetails {
  @apply flex items-center justify-center;
}

@screen md {
  .RestaurantIntro,
  .OffersSection {
    @apply px-8;
  }

  .DeliverDetails {
    @apply flex;
  }

  .OffersSection {
    @apply px-0 pb-3;
  }
}

@screen lg {
  .RestaurantIntro,
  .OffersSection {
    @apply px-8;
  }

  .DeliverData {
    @apply max-w-none flex px-4 mt-8 mx-auto bg-bg_f7f7f7 rounded-[8px];
  }

  .DeliverDetails {
    @apply flex;
  }

  .RestaurantData {
    @apply flex flex-col items-start;
    padding-inline-start: 20px;
  }

  .OffersSection {
    @apply py-20;
  }
}

@screen xl {
  .RestaurantIntro,
  .OffersSection {
    @apply px-12;
  }

  .RestaurantData {
    padding-inline-start: 20px;
  }
}

@screen 2xl {
  .RestaurantIntro,
  .OffersSection {
    @apply px-24;
  }

  .RestaurantData {
    padding-inline-start: 20px;
  }

  .DeliverDetails {
    @apply flex;
  }
}

@screen 3xl {
  .RestaurantIntro,
  .OffersSection {
    @apply px-24;
  }

  .RestaurantData {
    padding-inline-start: 50px;
  }

  .DeliverDetails {
    @apply flex;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
