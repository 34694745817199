/* MOBILE FIRST DEVELOPMENT */

.container {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
    @apply w-full bg-primary-50 h-10   ;
}

.progressBar {
    @apply bg-bg_e8e8e8 h-full w-full text-warm_grey flex items-center ltr:pl-4 rtl:pr-4 relative ;
}

.descriptionContainer {
    @apply absolute top-[20.5%] ltr:left-10 rtl:right-10   ;
}

@screen lg {
    .descriptionContainer {
        @apply absolute top-[16.8%] ltr:left-20  rtl:right-20;
    }
}