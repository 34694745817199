/** MOBILE FIRST DEVELOPMENT **/

.SideBarContainer {
  @apply absolute h-screen w-[280px] z-50 top-0 transition-all bg-side pt-20;
}

.SideItem {
  @apply w-full h-[65px] border-b-[1px] border-bg_e8e8e8 flex items-center justify-start px-6 cursor-pointer;
}

.overlay {
  @apply absolute h-screen w-full z-50 top-0 left-0 bg-[#32323280] transition-all;
}

@screen md {
  .container {
    @apply block py-3 px-8;
  }
  .MobileContainer {
    @apply hidden;
  }
  .navTopPartContainer {
    @apply relative;
  }
  .iconsContainer {
    @apply absolute w-max top-[4%] ltr:right-[10%] rtl:right-[52%];
  }
  .iconsContainerUser {
    @apply right-0 rtl:right-auto rtl:left-0 !important;
  }
  .icon {
    @apply w-6 h-6 ltr:mr-7 rtl:ml-7;
  }
  .iconRtl:last-child, .iconLTR:first-child {
    @apply  ltr:mr-0 rtl:ml-0 !important;
  }
  .Download {
    @apply hidden;
  }
  .logo {
    @apply w-9 h-9 cursor-pointer;
  }
}

@screen lg {
  .iconsContainer {
    @apply ltr:right-[7%] rtl:right-[70%] !important;
  }
  .iconsContainerUser {
    @apply rtl:right-[75.5%] ltr:right-[5%] !important;
  }
  .logo {
    @apply w-12 h-12;
  }
  .icon {
    @apply w-7 h-7;
  }
}

@screen xl {
  .container {
    @apply py-5 px-12;
  }
  .iconsContainer {
    @apply top-[15%] /* ltr:right-[10%]  */ rtl:right-[75.5%] !important;
  }
  .iconsContainerUser {
    @apply ltr:right-[3.5%] rtl:right-[82%] !important;
  }
}

@screen 2xl {
  .container {
    @apply py-[19px] px-24;
  }

  .logo {
    @apply w-[3.25rem] h-[3.25rem];
  }
 
  .iconsContainer {
    @apply ltr:right-[9.5%] /* rtl:right-[82%] */ !important;
  } 

  .iconsContainerUser {
    @apply ltr:right-[3.5%] rtl:right-[82%] !important;
  }
}

@screen 3xl {
  .logo {
    @apply w-16 h-16;
  }  
  .icon {
    @apply w-9 h-9;
  }
  .iconsContainer {
    @apply ltr:right-[9.5%] rtl:right-[73%];
  }
}
