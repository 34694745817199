/** MOBILE FIRST DEVELOPMENT **/

.nav {
  @apply fixed top-0 w-full z-20 bg-primary-50 shadow-[0_3px_6px_0_rgb(0,0,0,0.16)];
}

.location {
  @apply text-[14px] lg:text-[20px] fixed h-[fit-content] py-2 lg:top-[85px] md:top-[70px] sm:top-[114px]  top-[114px] w-full z-20 bg-primary-50 shadow-[0_3px_6px_0_rgb(0,0,0,0.16)] text-center;
}
.container {
  @apply hidden;
}

.MobileContainer {
  @apply flex justify-between items-center py-3 px-8 bg-bg_f7f7f7;
}

.Download {
  @apply flex w-full h-[40px] shadow-[0_3px_6px_0_rgb(0,0,0,0.16)] bg-white items-center justify-center text-center;
}

.navTopPartContainer {
  @apply flex items-center justify-between;
}

.logo {
  @apply w-[50px] h-[50px] cursor-pointer;
}

.iconsContainer {
  @apply flex items-center justify-between pt-4;
}

.icon {
  @apply w-8 h-8 cursor-pointer;
}

.MenuIcon {
  @apply w-6 h-6 cursor-pointer;
}

@screen md {
  .container {
    @apply block py-3 px-8;
  }
  .MobileContainer {
    @apply hidden;
  }
  .navTopPartContainer {
    @apply relative;
  }
  .iconsContainer {
    @apply absolute w-max top-[4%] ltr:right-[10%] rtl:right-[52%];
  }
  .iconsContainerUser {
    @apply right-[30px] rtl:right-auto rtl:left-[30px] !important;
  }
  .icon {
    @apply w-6 h-6 ltr:mr-7 rtl:ml-7;
  }
  .iconRtl:last-child,
  .iconLTR:first-child {
    @apply ltr:mr-0 rtl:ml-0 !important;
  }
  .Download {
    @apply hidden;
  }
  .logo {
    @apply w-9 h-9 cursor-pointer;
  }
}

@screen lg {
  .iconsContainer {
    @apply ltr:right-[7%] rtl:right-[70%] !important;
  }
  .iconsContainerUser {
    @apply rtl:right-[75.5%] ltr:right-[3%] !important;
  }
  .logo {
    @apply w-12 h-12;
  }
  .icon {
    @apply w-7 h-7;
  }
}

@screen xl {
  .container {
    @apply py-5 px-12;
  }
  .iconsContainer {
    @apply top-[15%] /* ltr:right-[10%]  */ rtl:right-[75.5%] !important;
  }
  .iconsContainerUser {
    @apply ltr:right-[3.5%] rtl:right-[69%] !important;
    /* @apply ltr:right-[3.5%] rtl:right-[82%] !important; */
  }
}

@screen 2xl {
  .container {
    @apply py-[19px] px-24;
  }

  .logo {
    @apply w-[3.25rem] h-[3.25rem];
  }

  .iconsContainer {
    @apply ltr:right-[9.5%] /* rtl:right-[82%] */ !important;
  }

  .iconsContainerUser {
    @apply ltr:right-[6%] rtl:right-[69%] !important;
    /* @apply ltr:right-[6%] rtl:right-[82%] !important; */
  }
}

@screen 3xl {
  .logo {
    @apply w-16 h-16;
  }
  .icon {
    @apply w-9 h-9;
  }
  .iconsContainer {
    @apply ltr:right-[9.5%] rtl:right-[73%];
  }
}
