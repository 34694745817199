/* MOBILE FIRST DEVELOPMENT */

.container {
    @apply px-6;
}

@screen lg {
    .main {
        @apply py-10 px-12;
    }
}