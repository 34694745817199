/* MOBILE FIRST DEVELOPMENT */

.container {
  @apply flex w-min flex-col items-center;
}

.popularFavResImgContainer {
  @apply bg-text_white flex justify-center items-center rounded-[10px] w-[230px] h-[140px];
}

@screen lg {
  .popularFavResImgContainer {
    @apply w-[230px] h-[140px];
  }
}

@screen 3xl {
  .popularFavResImgContainer {
    @apply w-[290px] h-[177px];
  }
}