.MuiOutlinedInput-root .Mui-focused{
  outline: none !important;
  color: transparent !important;
}

.textfield fieldset {
  /* border-color: #969696; */
}
.search_field fieldset {
  font-size: var(--small-font);
}
.textarea{
  height: 140px;
}
.textarea fieldset {
  height: 140px;
}
.field-label{
  position: absolute;
  top: -12px;
  font-size: 13px;
  margin: 0 20px;
  background-color: #fff;
  z-index: 99;
}
.password-icon{
  /* color: var(--primary-color) !important; */
  font-size: 13px !important;
  font-weight: lighter !important;
  width: 22px !important;
  height: 22px !important;
}
.textfield.search-input{
  background-color: #ffff !important;
}

.textfield.search-bg{
  background-color: #fff !important;
}

.notes fieldset{
  height: 100px;
}