/** MOBILE FIRST DEVELOPMENT **/
.form {
  @apply w-full h-full overflow-y-scroll;
}

.formInputsContainer {
  @apply mt-6 mr-1 rtl:ml-1;
}

@screen md {
  .formInputsContainer {
    @apply mr-2 rtl:ml-2;
  }
}

@screen lg {
  .formInputsContainer {
    @apply mt-5;
  }
}

@screen 3xl {
  .formInputsContainer {
    @apply mt-12;
  }
}
