/* MOBILE FIRST DEVELOPMENT */

.option {
    @apply p-3 flex items-center cursor-pointer border-t-[1px] border-bg_e8e8e8;
}

.option:hover {
    @apply bg-bg_e8e8e8;
}

.optionIcon {
    @apply w-[22px] h-[22px] mr-4 rtl:ml-4 rtl:mr-0;
}

@screen lg {
    .option {
        @apply p-5;
    }
}

@screen 2xl {
    .option {
        @apply p-6;
    }
}