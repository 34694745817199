/* MOBILE FIRST DEVELOPMENT */

.main {
    @apply bg-bg_f7f7f7 py-6 mt-[108px] md:mt-[60px] xl:mt-[84px] px-6;
}

@screen lg {
    .main {
        @apply py-16 px-[90px];
    }
}

@screen 3xl {
    .main {
        @apply py-20 px-[120px];
    }
}