.code_selectAR {
  width: 110px;
  margin: 0 0px 0 15px !important;
}
.code_selectEN {
  width: 110px;
  margin: 0 15px 0 0 !important;
}
.phone_field {
  width: 394px;
}

.select fieldset {
  border-color: #969696 !important;
}
.phone-select>svg{
  color: var(--primary-color) !important;
}
