/** MOBILE FIRST DEVELOPMENT **/

.main {
  @apply bg-bg_f7f7f7;
}


.OrderIntro {
  @apply bg-bg_f7f7f7 flex flex-col flex-wrap items-center w-full md:pt-16 pt-8 pb-4 md:pb-6 px-5;
}



@screen md {

  .OrderIntro {
    @apply px-8;
  }
}



@screen lg {

  .OrderIntro {
    @apply px-8;
  }
}


@screen xl {

  .OrderIntro {
    @apply px-12;
  }

}

@screen 2xl {

  .OrderIntro {
    @apply px-24;
  }

}

@screen 3xl {

  .OrderIntro {
    @apply px-24;
  }

}